<template>
    <div data-scroll
         :data-scroll-id="name"
         class="layout-9">
        <div v-observer
             :class="{dark:!lightMode}"
             class="content-container">
            <div v-for="(item,index) in items" :key="index" class="item">
                <div class="text-content">
                    <header-title :name="item.contentTitle" text-color="headerColor"/>
                    <div class="description" v-html="item.contentText"></div>
                </div>
                <div class="image-container">
                    <img :src="item.image" class="image" alt="">
                </div>
            </div>
        </div>
        <img v-if="!mobile" src="@/assets/images/layout-bg-image.png" alt="" class="background-image">
        <img v-else src="@/assets/images/layout-mobile-bg-image.png" alt="" class="background-image">

    </div>
</template>

<script>
import first from 'lodash/first'
import HeaderTitle from "@/components/custom/HeaderTitle";
import {mapGetters} from "vuex";

export default {
    props: {
        data: {
            type: Object,
            default: () => {
            }
        },
        config: {
            type: Object,
            default: () => {
            }
        },
        name: {
            type: String,
            default: ''
        },
        lightMode: {
            type: Boolean,
            default: false
        }
    },
    components: {
        HeaderTitle
    },
    computed: {
        ...mapGetters({
            device: 'resize/geDevice'
        }),
        items() {
            return this.data?.list?.map(item => {
                let image = first(item?.image)?.devices?.[this.device?.type]

                return {
                    background: item.background_color,
                    image,
                    imageItem: {
                        image,
                        title: item.image_title,
                        teaser: item.teaser,
                        description: item.image_description,
                    },
                    contentTitle: first(item?.content?.tabs)?.title,
                    contentText: first(item?.content?.tabs)?.content,
                    color: item.color1
                }
            })
        },
        header() {
            return this.config?.header?.title
        },
        headerColor() {
            return this.lightMode ? 'white' : '#0A141C'
        },
        mobile() {
            return this.device?.size < 1024
        }
    },

}
</script>

<style lang="scss" scoped>
$ease-out: cubic-bezier(.15, .16, .37, 1);

.layout-9 /deep/ {
    position: relative;

    .content-container {
        font-size: 21px;
        color: #0A141C;
        text-align: left;
        padding: 144px 375px 0;
        @media only screen and (max-width: 1650px) and (min-width: 1440px) {
            padding: 144px 165px 0;
        }
        @media only screen and (max-width: 1439px) and (min-width: 1366px) {
            padding: 144px 128px 0;
        }
        @media only screen and (max-width: 1365px) and (min-width: 768px) {
            padding: 89px 47px 0;
        }
        @media only screen and (max-width: 767px) {
            padding: 55px 16px 0;
        }
        @media only screen and (max-width: 1023px) {
            flex-direction: column;
        }

        &.dark {
            color: white;
        }

        .item {
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media only screen and (max-width: 1023px) {
                flex-direction: column;
                grid-gap: 55px;
            }

            .header-title {
                line-height: unset;
            }

            &:first-child {
                flex-direction: row-reverse;
                margin-bottom: 144px;
                @media only screen and (max-width: 1023px) {
                    flex-direction: column-reverse;
                    margin-bottom: 60px;
                }

                .image-container {
                    width: 371px;
                    height: 401px;
                    @media only screen and (max-width: 767px) {
                        width: 297px;
                        height: 321px;
                    }
                }
            }

            &:last-child {
                .image-container {
                    width: 188px;
                    height: 450px;
                    @media only screen and (max-width: 767px) {
                        width: 167px;
                        height: 401px;
                    }
                }
            }
        }

        .text-content {
            width: 50%;

            @media only screen and (max-width: 1023px) {
                width: 100%;
            }

            .title {
                margin-top: 44px;
                @media only screen and (max-width: 1023px) and (min-width: 768px) {
                    margin-top: 34px;
                }
                @media only screen and (max-width: 767px) {
                    margin-top: 21px;
                }

            }

            .description {
                margin-top: 10px;
                transition: all .4s $ease-out;
                @media only screen and (max-width: 1023px) {
                    margin-top: 34px;
                }

                p, span, div {
                    transition: all .4s $ease-out;
                }
            }
        }

        .image-container {
            width: fit-content;

            .image {
                width: 100%;
                height: 100%;
                object-fit: cover;
                @media only screen and (max-width: 1023px) {
                    margin: 0 auto;
                    display: block;
                    max-width: 100%;
                }

            }

            @media only screen and (max-width: 1023px) {
                width: 100%;
            }

        }
    }

    .background-image {
        position: absolute;
        opacity: .05;
        left: 0;
        pointer-events: none;
        top: 0;
        @media only screen and (max-width: 1023px) {
            opacity: 1;
            width: 100%;
        }
    }
}
</style>
